export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'About',
  },
  {
    path: 'pricing',
    label: 'Videos',
  },
  {
    path: 'testimonial',
    label: 'Testimonial',
  },
];
