import React from 'react'
import styled, { keyframes } from 'styled-components';
import c from '../assets/book.jpg'
import d from '../assets/grand.jpg'
import call from '../assets/call.png'
import Header2 from '../components/header/header2';

const Education = () => {
  const Container = styled.div`
  width: 100%;
  background: linear-gradient(#a0d2eb, #e5eaf5, #d0bdf4);
  position: relative;
  height: 100%;
 
`;
const Title = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
font-weight:600;
align-items: center;
font-size: 30px;
color:#f50556;
@media (max-width: 850px) {
    justify-content: center;
    align-items: center;
}
&>:nth-child(1){
    color:#f50556;
    margin-top: 0;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (max-width: 450px) {
      font-size: 23px;
    }
}
`
const GridContainer = styled.div`

display: grid;
grid-template-columns: 50% 50% ;
padding: 10px;
line-height:1.8em;
font-size:20px;
@media(max-width:1024px){
  grid-template-columns: 100% ;
}
}
`;
const GridContainer2 = styled.div`

display: grid;
gap: 3px;
padding: 3px;
grid-template-columns:repeat(2, 1fr);
grid-template-rows:auto auto auto;
grid-row-gap: -1px;
@media(max-width:512px){
  display:flex;
  flex-direction:column;
}
}
`
const GridItem2 = styled.div`
  display:flex;
  flex-direction:column;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  align-items:center;
}
`;

const GridItem = styled.div`
  display:flex;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  justify-content:center;
  align-items:center;
}
`;
const Card = styled.div`
--surface-color: #fff;
  --curve: 40;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }

.card {
  position: relative;
  display: block;
  height: 300px;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    
}
`;
  return (
    <>
    <Header2/>
    <Container>
     {/* Grid Item 1 */}
     <GridContainer>
     <GridItem style={{fontWeight:600,display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 1 */}
          <Title>Unlock Your Musical Potential with Arioso’s Education Services</Title>
          We believe that music education is the key to unlocking creativity and self-expression. Whether you're a beginner or an aspiring professional, our comprehensive education services provide the tools and guidance you need to thrive in the world of music.

        </GridItem>

        {/* Grid Item 2 */}
        <GridItem >
          {/* Content for Grid Item 2 */}
          <Card class="cards">
  <li>
    <a href="" class="card">
      <img src={call} class="card__image" alt="" />
      <div class="card__overlay">
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img class="card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">Get In Touch</h3>            
            <span class="card__status">Connect with us today, and let's craft excellence together</span>
          </div>
        </div>
        <p class="card__description">
For immediate support, drop us an email at <span style={{color:'blue'}}>ariosomusicstudios@gmail.com</span> or dial <span style={{color:'blue'}}>7838811859</span>. We're ready to assist you!</p>
      </div>
    </a>      
  </li>
</Card>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 3 */}
       <img src={d} width={'550px'} height={'800px'}></img>
        </GridItem>
        <GridItem style={{display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 4 */}
          <Title>Why Choose Arioso for Music Education?</Title>
       <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>Diverse Offerings</div>
        We offer a wide range of music education services, including music production, piano, composition, and music theory. You can choose the path that suits your interests and ambitions.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>⁠Customised Learning</div>
        We tailor our lessons to your individual needs and skill level, ensuring that you get the most out of each session.

</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>Flexible Scheduling</div>
We understand the demands of modern life. That's why we offer flexible scheduling to accommodate your busy lifestyle.

</GridItem2>

        
       </GridContainer2>
        </GridItem>
        <GridItem style={{display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 5 */}
          <Title>Our Education Services Include:</Title>
          <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>⁠Music Productiong</div>
        Explore the world of music production with our experienced instructors. Learn how to create, record, and produce your own music.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>Piano Lessons</div>
        Whether you're a beginner or looking to enhance your piano skills, our piano lessons cater to all levels and ages.

</GridItem2>
<GridItem2 >
<div style={{fontWeight:700}}>⁠Composition</div>
Unlock your creative potential and learn the art of music composition. Develop your unique musical voice.

</GridItem2>
<GridItem2 >
<div style={{fontWeight:700}}>Music Theory</div>
Build a solid foundation in music theory, which is essential for any aspiring musician. Understand the language of music.
</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>⁠Online Lessons</div>
We offer the option of virtual lessons for students who prefer to learn from the comfort of their own space.
</GridItem2>
       </GridContainer2>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 6 */}
          <img src={c} width={500} height={'400px'}></img>
        </GridItem>
        
        </GridContainer>
    </Container>
    </>
  )
}

export default Education