import React from 'react'
import Header2 from '../components/header/header2'
import styled, { keyframes } from 'styled-components';
import b from '../assets/new_img.jpg'
import d from '../assets/Poster.jpg'
import e from '../assets/poster_1.png'
import f from '../assets/poster_2.png'
import g from '../assets/poster_3.jpg'
import h from '../assets/poster_4.png'
import call from '../assets/call.png'
const Music_production = () => {
  const Image=styled.div`
  position:absolute;
  height:130px;
  width:130px;
  `;
  const Frame = styled.div`
  border:10px solid #222;
  height:130px;
  width:130px;
}
`;
const Title = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
font-weight:600;
align-items: center;
font-size: 30px;
color:#f50556;
@media (max-width: 850px) {
    justify-content: center;
    align-items: center;
}
&>:nth-child(1){
    color:#f50556;
    margin-top: 0;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (max-width: 450px) {
      font-size: 23px;
    }
}
`
const Border = styled.div`
background:white;
height:100%;
width:100%;
}
`;
const Card = styled.div`
--surface-color: #fff;
  --curve: 40;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }

.card {
  position: relative;
  display: block;
  height: 300px;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    
}
`;
  const Container = styled.div`
  width: 100%;
  background: linear-gradient(#a0d2eb, #e5eaf5, #d0bdf4);
  position: relative;
  padding-bottom:5rem;
  height: 100%;
  /* Add your pastel texture image and adjust the opacity as needed */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    opacity: 0.1; /* Adjust the opacity to control the intensity of the texture */
  }
`;
const GridContainer = styled.div`

display: grid;
grid-template-columns: 50% 50% ;
padding: 10px;
line-height:1.8em;
font-size:20px;
@media(max-width:1024px){
  grid-template-columns: 100% ;
}
}
`;
const GridContainer2 = styled.div`

display: grid;
gap: 3px;
padding: 3px;
grid-template-columns:repeat(2, 1fr);
grid-template-rows:auto auto auto;
@media(max-width:512px){
  display:flex;
  flex-direction:column;
}
}
`
const GridItem2 = styled.div`
  display:flex;
  flex-direction:column;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  align-items:center;
}
`;
const GridItem = styled.div`
  display:flex;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  justify-content:center;
  align-items:center;
}
`;

  return (
    <>
    <Header2/>
    <Container>
     {/* Grid Item 1 */}
     <GridContainer>
     <GridItem style={{fontWeight:600,display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 1 */}
          <Title>Elevate Your Sound with Arioso’s Music Production Services</Title>
          We understand that music is more than just notes on a page or melodies in the air, it's an emotional journey, a storytelling medium, and an art form that deserves to be heard at its best. Our music production services are designed to bring your musical vision to life, whether you're an emerging artist or an established professional.

        </GridItem>

        {/* Grid Item 2 */}
       
        <GridItem >
          {/* Content for Grid Item 2 */}
          <Card class="cards">
  <li>
    <a href="" class="card">
      <img src={call} class="card__image" alt="" />
      <div class="card__overlay">
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img class="card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">Get In Touch</h3>            
            <span class="card__status">Connect with us today, and let's craft excellence together</span>
          </div>
        </div>
        <p class="card__description">
        For immediate support, drop us an email at <span style={{color:'blue'}}>ariosomusicstudios@gmail.com</span> or dial <span style={{color:'blue'}}>7838811859</span>. We're ready to assist you!</p>
      </div>
    </a>      
  </li>
</Card>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 3 */}
          <img
  src={b}
  width={600}
  style={{
    zIndex: 100,
    borderRadius: '1rem',
    opacity: '0.9',
    '@media (max-width: 768px)': {
      width: '100%', // Set the image width to 100% for smaller screens
      borderRadius: '0', // Remove border-radius for smaller screens
    },
  }}
/>
        </GridItem>
        
        <GridItem style={{display:'flex', flexDirection:'column'}}>
          {/* Content for Grid Item 4 */}
          <Title>Why Choose Arioso For Music Production</Title>
       <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>⁠Experienced Producers</div>
         Our team of experienced producers, engineers, and musicians are dedicated to helping you achieve your musical goals. With years of industry experience, we understand the nuances of different musical genres.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>Tailored Solutions</div>
        We work closely with you to understand your unique sound and artistic vision. Your music will be treated with the care and attention it deserves.

</GridItem2>
<GridItem2>
<div style={{fontWeight:700}}>Mixing and Mastering</div>
From recording and mixing to mastering, we handle every step of the production process to ensure that your music sounds pristine and ready for the world.

</GridItem2>
<GridItem2 >
<div style={{fontWeight:700}}>⁠Original Composition</div>
If you're looking for original music or custom arrangements, our team of composers can create the perfect soundtrack for your project.

</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>⁠Collaborative Environment</div>
 We encourage collaboration and welcome artists to be involved in the creative process, ensuring that your music stays true to your vision.

</GridItem2>
        
       </GridContainer2>
        </GridItem>
        <GridItem style={{display:'flex', flexDirection:'column'}}>
          {/* Content for Grid Item 5 */}
          <Title>Our Music Production Services include</Title>
          <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>⁠Mixing and Mastering</div>
        Our skilled engineers will meticulously mix and master your tracks to bring out their full potential.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>Composition and Arrangement</div>
        Need original music or custom arrangements for your project? Our composers can create music tailored to your needs
</GridItem2>
<GridItem2>
<div style={{fontWeight:700}}>Sound Design</div>
We offer professional sound design services for various media projects.
</GridItem2>
<GridItem2 >
<div style={{fontWeight:700}}> ⁠Scoring for Film and Media</div>
Our team can create film scores, jingles, and background music to enhance your visual projects.

</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>Remote Recording</div>
For remote collaboration and interviews, we provide solutions to maintain audio quality and clarity.
</GridItem2>
        
       </GridContainer2>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 6 */}
       <GridContainer2 style={{gap:'1px',padding: '1px'} }>
        <GridItem2><Frame><Border><Image><img src={d} height="130" width="130" alt=""></img></Image></Border></Frame></GridItem2>
        <GridItem2><Frame><Border><Image><img src={h} height="130" width="130" alt=""></img></Image></Border></Frame></GridItem2>
        <GridItem2><Frame><Border><Image><img src={f} height="130" width="130" alt=""></img></Image></Border></Frame></GridItem2>
        <GridItem2><Frame><Border><Image><img src={g} height="130" width="130" alt=""></img></Image></Border></Frame></GridItem2>
        <GridItem2><Frame><Border><Image><img src={e} height="130" width="130" alt=""></img></Image></Border></Frame></GridItem2>
       </GridContainer2>
        </GridItem>

        </GridContainer>
        <h1 style={{textAlign:'center',color:'#f50556'}}>We will be opening for In-Studio Recordings Soon!</h1>
    </Container>
    </>
  )
}

export default Music_production