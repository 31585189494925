import React from 'react'
import Header from '../components/header/header'
import styled, { keyframes } from 'styled-components';
import b from '../assets/harry.jpg';
import c from '../assets/podcast_img.jpeg'
import call from '../assets/call.png'
import Header2 from '../components/header/header2';
const Podcast = () => {
  const Container = styled.div`
  width: 100%;
  background: linear-gradient(#a0d2eb, #e5eaf5, #d0bdf4);
  position: relative;
  padding-bottom:5rem;
  height: 100%;


`;
const GridContainer = styled.div`

display: grid;
grid-template-columns: 50% 50% ;
padding: 10px;
line-height:1.8em;
font-size:20px;
@media(max-width:1024px){
  grid-template-columns: 100% ;
}
}
`;
const Title = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
font-weight:600;
align-items: center;
font-size: 30px;
color:#f50556;
@media (max-width: 850px) {
    justify-content: center;
    align-items: center;
}
&>:nth-child(1){
    color:#f50556;
    margin-top: 0;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (max-width: 450px) {
      font-size: 23px;
    }
}
`
const GridContainer2 = styled.div`

display: grid;
gap: 3px;
padding: 3px;
grid-template-columns:50% 50%;
@media(max-width:512px){
  display:flex;
  flex-direction:column;
}
}
`
const GridItem2 = styled.div`
  display:flex;
  flex-direction:column;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  align-items:center;
}
`;
const GridItem = styled.div`
  display:flex;
  padding: 20px;
  font-size: 30px;
  text-align:center;
  font-size:20px;
  justify-content:center;
  align-items:center;
}
`;
const Card = styled.div`
--surface-color: #fff;
  --curve: 40;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }

.card {
  position: relative;
  display: block;
  height: 300px;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    
}
`;
  return (
    <>
    <Header2 />
    <Container>
     {/* Grid Item 1 */}
     <GridContainer>
     <GridItem style={{fontWeight:600,display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 1 */}
          <Title></Title>
          At Arioso Studios, we're passionate about bringing your podcast ideas to life. Our mission is to help you create engaging and professional podcasts that captivate your audience. Whether you're a seasoned podcaster or just starting out, we've got you covered with our top-notch podcast production services.

        </GridItem>

        {/* Grid Item 2 */}
        <GridItem >
          {/* Content for Grid Item 2 */}
          <Card class="cards">
  <li>
    <a href="" class="card">
      <img src={call} class="card__image" alt="" />
      <div class="card__overlay">
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img class="card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">Get In Touch</h3>            
            <span class="card__status">Connect with us today, and let's craft excellence together</span>
          </div>
        </div>
        <p class="card__description">
        For immediate support, drop us an email at <span style={{color:'blue'}}>ariosomusicstudios@gmail.com</span> or dial <span style={{color:'blue'}}>7838811859</span>. We're ready to assist you!</p>
      </div>
    </a>      
  </li>
</Card>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 3 */}
       <img src={b} width={500}></img>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 4 */}
       <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>⁠Expertise in Audio</div>
        With our extensive experience in music production and sound engineering, we understand the importance of high-quality audio. Your podcast will sound crystal clear and professional.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>Tailored Solutions</div>
        We work closely with you to understand your podcast's unique style and content, ensuring that every episode is a true reflection of your vision.

</GridItem2>
<GridItem2>
<div style={{fontWeight:700}}>Editing and Post-Production</div>
From editing and sound design to music selection and mastering, we handle every aspect of post-production to make your podcast stand out.

</GridItem2>
<GridItem2 >
<div style={{fontWeight:700}}>⁠Custom Music and Soundscapes</div>
As a music production studio, we can create custom music and soundscapes to enhance your podcast and give it a distinctive identity.
</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>Timely Delivery</div>
We understand the importance of consistency in podcasting. You can rely on us to deliver episodes on schedule.

</GridItem2>
        
       </GridContainer2>
        </GridItem>
        <GridItem style={{display:'flex',flexDirection:'column'}}>
          {/* Content for Grid Item 5 */}
          <Title>Our Podcast Production Services Include:</Title>
          <GridContainer2>
        <GridItem2>
        <div style={{fontWeight:700}}>Editing and Mixing</div>
        Our skilled team will meticulously edit and mix your podcast to ensure it meets the highest industry standards.

        </GridItem2>
        <GridItem2>
        <div style={{fontWeight:700}}>Original Music</div>
        Let us compose original music or create unique soundscapes to enhance your podcast.

</GridItem2>
<GridItem2 style={{gridColumn: 'span 2' }}>
<div style={{fontWeight:700}}>Remote Recording</div>
For remote interviews and co-hosts, we offer solutions to maintain audio quality and clarity.

</GridItem2>
        
       </GridContainer2>
        </GridItem>
        <GridItem >
          {/* Content for Grid Item 6 */}
          <img src={c} width={500} style={{
    zIndex: 100,
  }}></img>
        </GridItem>

        </GridContainer>
       <h1 style={{textAlign:'center',color:'#f50556'}}>We will be opening for In-Studio Recordings Soon!</h1>
    </Container>
    </>
  )
}

export default Podcast