import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';
import React, { Suspense } from 'react';
import FlippingSquare from "react-cssfx-loading/lib/FlippingSquare";
import styled from 'styled-components';
import Sponsors from './components/Sponsors';
import { Route,Routes } from 'react-router-dom';
import Education from './pages/Education.js';
import Music_production from './pages/Music_production.js';
import Podcast from './pages/Podcast.js';
import a from '../src/assets/bg3.jpeg';



const Layout = React.lazy(() => import('./components/header/layout'));
const Home = React.lazy(() => import('./components/Home'));
const Products = React.lazy(() => import('./components/Products'));
const Pricing = React.lazy(() => import('./components/Pricing'));
const Footer = React.lazy(() => import('./components/Footer'));
const KeyFeature = React.lazy(() => import('./components/KeyFeature'));
const QualityFeatures = React.lazy(() => import('./components/QualityFeatures'));
const CoreFeatures = React.lazy(() => import('./components/CoreFeatures'));
const Process = React.lazy(() => import('./components/Process'));
const Team = React.lazy(() => import('./components/Team'));
const Testimonial = React.lazy(() => import('./components/Testimonial'));
const Blog = React.lazy(() => import('./components/Blog'));
const Subscribe = React.lazy(() => import('./components/Subscribe'));

const Center = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
width: 100vw;

`
const Loader = () => (
  <Center>
    <FlippingSquare color="#000" width="100px" height="100px" />
  </Center>
)

function App() {
  const Container = styled.div`
  width: 100%;
  background: linear-gradient(#fff,#fff);
  position: relative;
  padding-bottom:5rem;
  height: 100%;

  /* Add your pastel texture image and adjust the opacity as needed */
  // ::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(${a});
  //   opacity: 0.1; /* Adjust the opacity to control the intensity of the texture */
  // }
`;
const Container2 = styled.div`
width: 100%;
background: linear-gradient(#fff,#a0d2eb, #e5eaf5, #d0bdf4);
position: relative;
padding-bottom:5rem;
height: 100%;
`
  return (
    <div style={{ overflow: 'hidden' }}>
        <Routes>
        <Route path='/' element={ 
        <Suspense fallback={<Loader />}>
        <Layout />
        <Container>
        <Home />
        <Container2>
        <Products />
        
        <KeyFeature />
        <QualityFeatures />  
        <Sponsors />
        <CoreFeatures />
        
        <Process />
        
        <Pricing />
       
        <Team />
        <Testimonial />
        <Blog />
        </Container2>
        <Subscribe />
        
        </Container>
        
        <Footer />
      </Suspense>
    } />
      <Route path='/education' element={<Education/>} />
          <Route path='/podcast' element={<Podcast />} />
          <Route path='/music_production' element={<Music_production />} />
        </Routes>
     

    </div>
  );
}

export default App;
