import MobileDrawer from './mobile-drawer';
import styled, { keyframes } from 'styled-components';
import { Link  as ReactRouterLink} from 'react-router-dom';
import a from '../../assets/arioso_logo.png'

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }

  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`
const Container = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content: space-evenly;
background-color: transparent;
height: 10vh;
transition: all 0.5s ease;
padding-top: 0.5rem;
animation: ${positionAnim} 0.5s ease;
&.sticky{
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  padding-top: 0;
}
`
const SignUp = styled.div`
display: flex;
flex: 1;
justify-content: center;
&>*{
  cursor: pointer;
  font-size: 1rem;
  border-radius: 2rem;
  padding: 7px 16px;
  color: black;
  background-color: #fff;
  transition: all 0.3s ease;
  font-weight:700;
  text-decoration:none;
  &:hover{
    color: #f50056;
    transform: translateY(-10%);
  }
  @media (max-width: 1024px) {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 1rem;
  }
  @media (max-width: 580px) {
    padding: 2px 5px;
    border-radius: 1 rem;
    font-size: 0.5rem;
    transform: translateY(30%);
  }
  @media (max-width: 390px) {
    padding: 1px 2px;
    border-radius: 1 rem;
    font-size: 0.5rem;
    transform: translateY(30%);
  }
}
`
const Logo = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center; 
font-family: 'Mochiy Pop P One', sans-serif;
font-size: 1.5rem;

&>*{
  cursor: pointer;
  text-decoration:none;
  padding:0.7rem;
}
@media (max-width: 1024px) {
  font-size: 1.2rem;
  padding:0.8rem;
}
@media (max-width: 512px) {
  font-size: 0.8rem;
  padding:0.5rem;
}
`

export default function Header2({ className }) {
  return (
        <Container className={className}>
          
          <Logo>
          <img src={a} width={'40px'}></img>
          <ReactRouterLink to={'/'}> <span>Arioso Studios</span></ReactRouterLink>
          </Logo>
         
          <SignUp>
          <ReactRouterLink to='/music_production'><span>Production</span></ReactRouterLink>
          <ReactRouterLink to='/podcast'><span>Podcast</span></ReactRouterLink>
           <ReactRouterLink to='/education'> <span>Education</span></ReactRouterLink>
           
          
          </SignUp>
          <MobileDrawer />
        </Container>
  );
}